import _ from 'lodash'
import {
  COMPONENT_TYPES,
  FormsFieldPreset,
  FormPlugin,
  FormPreset,
  SuccessActionTypes,
} from '@wix/forms-common'
import { ROLE_FORM, ROLE_MESSAGE, FIELDS_ROLES } from '../../../constants/roles'
import successMessageStructure from '../../../assets/presets/hidden-message.json'
import submitButtonStructure from '../../../assets/presets/submit-button.json'
import { skinToStyleMapping, translateContactFormStyle } from './contact-form-style'
import { fieldsStore } from '../preset/fields/fields-store'
import { createSuffixedName } from '../../../utils/utils'
import { enhanceFormWithMobileStructure } from './contact-form-mobile-service'
import { enhanceFormWithDesktopStructure } from './contact-form-layout'
import translations from '../../../utils/translations'
import { FontOption } from '@wix/platform-editor-sdk'
import sideInputLabel from '../../../assets/presets/side-input-label.json'

export const getTextInputComponent = (convertedForm: RawComponentStructure) =>
  _.find(convertedForm.components, {
    componentType: COMPONENT_TYPES.TEXT_INPUT,
  })
export const getButtonComponent = (convertedForm: RawComponentStructure) =>
  _.find(convertedForm.components, {
    componentType: COMPONENT_TYPES.SITE_BUTTON,
  })

export type DynamicContactFormEnhancer = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  { mobileLayouts, desktopLayouts },
  fontOptions?: FontOption[],
) => RawComponentStructure

export type ContactFormEnhancer = (
  contactForm: ContactForm,
  convertedForm: RawComponentStructure,
  { mobileLayouts, desktopLayouts },
  fontOptions?: FontOption[],
) => RawComponentStructure

export type ContactFormFieldEnhancer = (
  contactForm: ContactForm,
  dynamicField: ContactFormField,
  convertedField: RawComponentStructure,
) => RawComponentStructure

export const isSideLabelSkin = (skin: string) =>
  skin === 'wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft'
export const isSkinWithFieldTitles = (skin: string) =>
  skin === 'wysiwyg.viewer.skins.contactform.VerticalForm'
export const createBaseStructure = (contactForm: ContactForm): RawComponentStructure => ({
  type: 'Container',
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  layout: {
    x: contactForm.layout.x,
    y: contactForm.layout.y,
    width: contactForm.layout.width,
    height: contactForm.layout.height,
  },
  componentType: COMPONENT_TYPES.FORM_CONTAINER,
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style: {
      properties: {
        bg: '#FFFFFF',
        'alpha-bg': 0,
      },
      propertiesSource: {
        bg: 'value',
      },
    },
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  },
  role: ROLE_FORM,
  config: {
    labels: ['contacts-contacted_me'],
    errorMessage: 'Fill in missing fields.',
    secondsToResetForm: 3,
  },
})

export const createSubmitButtonComponent = (contactForm: ContactForm): RawComponentStructure =>
  _.merge({}, submitButtonStructure, {
    layout: {
      width: 142,
      height: 40,
      x: 60,
      y: 52,
    },
    data: {
      label: contactForm.data.submitButtonLabel || 'Send',
    },
  })

export const skinWithCenterMessages = [
  'contactform.FullWidthButtonSkin',
  'contactform.LineOnlySkin',
  'contactform.OverlappingButtonSkin',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon',
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny',
]

const createSuccessMessageComponent = (contactForm: ContactForm): RawComponentStructure => {
  return _.merge({}, successMessageStructure, {
    layout: {
      width: contactForm.layout.width - 60,
      height: 21,
      x: 60,
      y: 102,
    },
    data: {
      text: `<p class="font_8"><span style="color:#8FCA8F;">${
        contactForm.data.successMessage || translations.t('contactForm.defaultSuccessMessage')
      }</span></p>`,
    },
  })
}

export const convertContactFormToWixForms = (
  contactForm: DynamicContactForm,
  fontOptions,
  { mobileLayouts, desktopLayouts },
): RawComponentStructure => {
  const dynamicFields = fetchDynamicFields(contactForm)
  const components: RawComponentStructure[] = [
    ...dynamicFields,
    createSubmitButtonComponent(contactForm),
    createSuccessMessageComponent(contactForm),
  ]
  const baseStructure = _.merge({}, createBaseStructure(contactForm), {
    components,
    config: {
      preset: FormPreset.CONTACT_FORM,
      formName: contactForm.data.formName || 'Contact',
      plugins: [
        {
          id: FormPlugin.FORM_BUILDER,
        },
      ],
    },
  })

  const enhancers: (ContactFormEnhancer | DynamicContactFormEnhancer)[] = [
    fixSideLabelSkin,
    behaviorsEnhancer,
    emailEnhancer,
    handleAfterSubmitBehavior,
    hideFormInMobile,
    enhanceWithStyle,
    enhanceFormWithMobileStructure,
    enhanceFormWithDesktopStructure,
    fixDuplicatedCrmLabels,
  ]
  const convertedStructure = enhancers.reduce<RawComponentStructure>(
    (previousStructure, enhancer) =>
      enhancer(contactForm, previousStructure, { desktopLayouts, mobileLayouts }, fontOptions),
    baseStructure,
  )
  return convertedStructure
}

const fixSideLabelSkin = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
) => {
  if (!isSideLabelSkin(contactForm.skin)) {
    return convertedForm
  }
  return {
    ...convertedForm,
    components: convertedForm.components.reduce((currComponents, component) => {
      if (_.includes(FIELDS_ROLES, component.role)) {
        const labelText = component.data.placeholder
        const inputComponent = _.merge({}, component, {
          data: { placeholder: '' },
          props: { placeholder: '' },
        })
        const labelComponent = _.merge({}, sideInputLabel, {
          data: {
            text: `<p class="font_8"><span style="color:#000000;">${labelText}</span></p>`,
          },
        })
        return [...currComponents, labelComponent, inputComponent]
      } else {
        return [...currComponents, component]
      }
    }, []),
  }
}
const createBaseField = (contactFormField: DynamicContactFormField) => {
  switch (contactFormField.name) {
    case 'name':
      return fieldsStore.allFieldsData[FormsFieldPreset.FIRST_NAME]
    case 'address':
      return fieldsStore.allFieldsData[FormsFieldPreset.ADDRESS]
    case 'phone':
      return fieldsStore.allFieldsData[FormsFieldPreset.PHONE]
    case 'email':
      return fieldsStore.allFieldsData[FormsFieldPreset.MAIN_EMAIL]
    case 'secondaryEmail':
      return fieldsStore.allFieldsData[FormsFieldPreset.EMAIL]
    case 'message':
      return fieldsStore.allFieldsData[FormsFieldPreset.GENERAL_TEXT_BOX]
    default:
      return fieldsStore.allFieldsData[FormsFieldPreset.GENERAL_TEXT]
  }
}

const createBaseFieldHorizontal = (contactFormField: DynamicContactFormField) => {
  switch (contactFormField.name) {
    case 'message':
      return fieldsStore.allFieldsData[FormsFieldPreset.GENERAL_TEXT]
    default:
      return createBaseField(contactFormField)
  }
}
const createBaseFieldPerSkin = {
  'wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin': createBaseFieldHorizontal,
}

export const updateConfig: ContactFormFieldEnhancer = (
  _contactForm: DynamicContactForm,
  _dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.config = { ...(convertedField as any).connectionConfig }
  delete (convertedField as any).connectionConfig
  return convertedField
}

export const updateCrmLabel: ContactFormFieldEnhancer = (
  _contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.config.crmLabel =
    dynamicField.displayLabel.substring(0, 25).trim() || dynamicField.name
  return convertedField
}

const updateLabel: ContactFormFieldEnhancer = (
  contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  if (isSkinWithFieldTitles(contactForm.skin)) {
    convertedField.data.placeholder = ''
    convertedField.props.placeholder = ''
    convertedField.data.label = dynamicField.displayLabel
  }

  return convertedField
}

export const updatePlaceholder: ContactFormFieldEnhancer = (
  contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  const content = `${dynamicField.displayLabel}${dynamicField.required ? ' *' : ''}`
  if (!isSkinWithFieldTitles(contactForm.skin)) {
    convertedField.data.label = ''
    convertedField.data.placeholder = content
    convertedField.props.placeholder = content
  }

  return convertedField
}

export const updateRequired: ContactFormFieldEnhancer = (
  _contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.props.required = dynamicField.required
  return convertedField
}

export const updateTextAlignment: ContactFormFieldEnhancer = (
  contactForm: DynamicContactForm,
  _dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.props.textAlignment = contactForm.data.textDirection
  return convertedField
}

export const fixDuplicatedCrmLabels: ContactFormEnhancer = (
  _contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
) => {
  const crmLabels = []

  _.forEach(convertedForm.components, (field) => {
    if (!_.includes([COMPONENT_TYPES.TEXT_INPUT, COMPONENT_TYPES.TEXT_AREA], field.componentType)) {
      return
    }

    if (_.includes(crmLabels, field.config.crmLabel)) {
      field.config.crmLabel = createSuffixedName(crmLabels, field.config.crmLabel)
    }

    crmLabels.push(field.config.crmLabel)
  })

  return convertedForm
}

const fieldEnhancers: ContactFormFieldEnhancer[] = [
  updateConfig,
  updateCrmLabel,
  updateLabel,
  updatePlaceholder,
  updateRequired,
  updateTextAlignment,
]

const fetchDynamicFields = (contactForm: DynamicContactForm) => {
  return _.map(contactForm.data.dynamicFields, (field) => {
    const skinBaseField = createBaseFieldPerSkin[contactForm.skin] || createBaseField
    const baseField = skinBaseField(field).fieldStructure() as RawComponentStructure

    const convertedFieldStructure = fieldEnhancers.reduce<RawComponentStructure>(
      (previousFieldStructure, enhancer) => enhancer(contactForm, field, previousFieldStructure),
      baseField,
    )

    return convertedFieldStructure
  })
}

export const hideFormInMobile: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  if (!_.get(contactForm.mobileHints, 'hidden')) {
    return structure
  }
  const hideFormInMobileAction: (structure: RawComponentStructure) => RawComponentStructure = (
    structure,
  ) => {
    const structureWithMobileHidden: RawComponentStructure = {
      ...structure,
      mobileHints: { hidden: true, type: 'MobileHints' },
    }
    if (structure.components) {
      return {
        ...structureWithMobileHidden,
        components: structureWithMobileHidden.components.map((component) =>
          hideFormInMobileAction(component),
        ),
      }
    } else {
      return structureWithMobileHidden
    }
  }
  return hideFormInMobileAction(structure)
}

export const handleAfterSubmitBehavior: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  const shouldShowLink = contactForm.data.onSubmitBehavior === 'link'
  const enhancedStructure = _.merge({}, structure, {
    config: {
      successActionType: shouldShowLink ? SuccessActionTypes.LINK : SuccessActionTypes.SHOW_MESSAGE,
      successLinkValue: shouldShowLink ? contactForm.data.link : undefined,
    },
  })
  if (shouldShowLink) {
    enhancedStructure.components = structure.components.filter(
      (component) => component.role !== ROLE_MESSAGE,
    )
  }
  return enhancedStructure
}

export const emailEnhancer: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  return {
    ...structure,
    config: {
      ...structure.config,
      emailId: '',
      emailIds: [contactForm.data.toEmailAddress, contactForm.data.bccEmailAddress].filter(
        (email) => email,
      ),
    },
  }
}

export const behaviorsEnhancer: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  return {
    ...structure,
    behaviors: contactForm.behaviors,
  }
}

export const enhanceWithStyle: ContactFormEnhancer = (
  contactForm: ContactForm,
  structure: RawComponentStructure,
  _layouts,
  fontOptions: FontOption[],
): RawComponentStructure => {
  const skinTranslator = skinToStyleMapping[contactForm.skin]
  const enhanceWithStyleAction: (structure: RawComponentStructure) => RawComponentStructure = (
    structure,
  ) => {
    const componentTranslatorCreator = _.get(skinTranslator, 'translator')
    const componentTranslator = componentTranslatorCreator
      ? componentTranslatorCreator()[structure.componentType]
      : null
    const componentWithStyle: RawComponentStructure = componentTranslator
      ? translateContactFormStyle(
          _.get(componentTranslator, structure.role, componentTranslator),
          skinTranslator.defaultValues,
          contactForm,
          structure,
          fontOptions,
        )
      : structure
    if (structure.components) {
      return {
        ...componentWithStyle,
        components: componentWithStyle.components.map((component) =>
          enhanceWithStyleAction(component),
        ),
      }
    } else {
      return componentWithStyle
    }
  }
  return enhanceWithStyleAction(structure)
}

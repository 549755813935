import { EditorPlatformApp } from '@wix/platform-editor-sdk'
import { editorAppMetaData } from './editor-app'

// i'm not connecting it at the moment to our exports
export const handleAction: EditorPlatformApp['handleAction'] = async ({ type, payload }) => {
  switch (type) {
    case 'migrate': {
      const api = await editorAppMetaData.getCoreApi()
      api.interactionStarted('contact-form-migration')
      if (api.isADI()) {
        await new Promise((resolve) => setTimeout(() => resolve(), 3000))
      }
      await api.addForm.convertAllContactForms(payload as any)
      api.interactionEnded('contact-form-migration')
    }
    default:
      break
  }
}
